import axios from '@axios'

export default {
  namespaced: true,
  state: {
    mainProducts: [],
    product: {},
  },
  getters: {},
  mutations: {
    setMainProducts(state, mainProducts) {
      state.mainProducts = mainProducts
    },
    setProductItem(state, product) {
      state.product = product
    },
  },
  actions: {
    fetchMainProducts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/main-products', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchProducts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/my/products', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProduct(ctx, { id, params = {} }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/my/products/${id}`, { params: params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProductHistory(ctx, { id, params = {} }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/my/product/history/${id}`, { params: params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchClients() {
      return new Promise((resolve, reject) => {
        axios
          .get('/my/products/clients')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },


    addProduct(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/my/products', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateProduct(ctx, { id, params }) {
      return new Promise((resolve, reject) => {
        axios.patch(`/my/products/${id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteProduct(ctx, productId) {

      return new Promise((resolve, reject) => {
        axios
          .delete(`/my/products/${productId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
