export default [


   {
      path: '/',
      // name: 'app-front',  
      meta: {
         // layout: 'full',
         requiresAuth: false
      },
      component: () => import("@/views/front/index.vue"),
      /*{ name: 'apps-dashboard' }*/
      children: [
         {
            name: "front.Home",
            path: "",
            component: () => import("@/views/front/cataloge/shop/index.vue"),
            meta: {
               //    layout: 'full',
               requiresAuth: false
            },
         },

         {
            path: '/cataloge/products/wishlist',
            name: 'cataloge-wishlist',
            component: () => import('@/views/front/cataloge/wishlist/Wishlist.vue'),
            meta: {
               pageTitle: 'Wishlist',
               contentClass: 'ecommerce-application',
               breadcrumb: [
                  {
                     text: 'ECommerce',
                  },
                  {
                     text: 'Wishlist',
                     active: true,
                  },
               ],
            },
         },
         {
            path: '/cataloge/products/checkout',
            name: 'cataloge-checkout',
            component: () => import('@/views/front/cataloge/checkout/index.vue'),
            meta: {
               //  pageTitle: 'Checkout',
               contentClass: 'ecommerce-application',
               // breadcrumb: [
               //    {
               //       text: 'ECommerce',
               //    },
               //    {
               //       text: 'Checkout',
               //       active: true,
               //    },
               // ],
            },
         },
         {
            path: '/cataloge/products/:slug',
            name: 'cataloge-product-details',
            component: () => import('@/views/front/cataloge/product-details/index.vue'),
            meta: {
               // pageTitle: 'تفاصيل المنتج',
               contentClass: 'ecommerce-application',
               // breadcrumb: [
               //    {
               //       text: 'الرئيسية',
               //    },
               //    {
               //       text: 'المنتجات',
               //       active: true,
               //    },
               //    {
               //       text: 'تفاصيل المنتج',
               //       active: true,
               //    },
               // ],
            },
         },

         //            {
         //                 name: "about.index",
         //                 path: "about-us", 
         //                 component: () =>  import("@/views/front/About"),
         //                   meta: {
         //     requiresAuth: false
         // },
         //             },   
         //                {
         //                 name: "privacy.index",
         //                 path: "/privacy", 
         //                 component: () =>  import("@/views/front/Privacy"),
         //                   meta: {
         //     requiresAuth: false
         // },
         //             },    

         {
            path: '/my/products',
            name: 'admin.products.index',
            component: () => import('@/views/front/products/index.vue'),
         },
         // {
         //    path: '/apps/invoice/preview/:id',
         //    name: 'apps-invoice-preview',
         //    component: () => import('@/views/apps/invoice/invoice-preview/InvoicePreview.vue'),
         // },

         {
            path: '/my/products/create/',
            name: 'admin.products.create',
            component: () => import('@/views/front/products/create.vue'),
         },

         {
            path: '/my/products/:id/edit',
            name: 'admin.products.edit',
            component: () => import('@/views/front/products/edit.vue'),
         },

         {
            path: '/my/products/show/:id',
            name: 'admin.products.show',
            component: () => import('@/views/front/products/show.vue'),
         },
         {
            path: '/my/orders',
            name: 'admin.orders.index',
            component: () => import('@/views/front/orders/index.vue'),
         },

         // {
         //    path: '/my/orders/show/:id',
         //    name: 'admin.orders.show',
         //    component: () => import('@/views/front/orders/create.vue'),
         // },

         {
            path: '/my/orders/show/:id',
            name: 'admin.orders.show',
            component: () => import('@/views/front/orders/show.vue'),
         },

         {
            path: '/my/wallets',
            name: 'admin.wallets.index',
            component: () => import('@/views/front/wallets/index.vue'),
         },


      ]
   },



];