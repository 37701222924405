import ability from './ability'
import Vue from 'vue'


let can = (action, subject) => {

  return true;
    if(!ability.rules) return false;
 let exist=ability.rules.find(n=> n.action==action && n.resource==subject)
  return exist ? true : false
}

//Vue.prototype.$can=can
//export const canNavigate = to => to.matched.some(route => ability.can(route.meta.action, route.meta.resource))
export const canNavigate = to => to.matched.some(route => can(route.meta.action, route.meta.resource))

export const _ = undefined
