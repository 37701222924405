import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'

Vue.component(FeatherIcon.name, FeatherIcon)


import Meta from "vue-meta";
import BootstrapVue from 'bootstrap-vue/dist/bootstrap-vue.esm';
import {  BootstrapVueIcons } from 'bootstrap-vue'
import VueGoodTablePlugin from "vue-good-table";


    Vue.use(BootstrapVue);
    Vue.use(BootstrapVueIcons);

        Vue.use(Meta, {
      keyName: "metaInfo",
      attribute: "data-vue-meta",
      ssrAttribute: "data-vue-meta-server-rendered",
      tagIDKeyName: "vmid",
      refreshOnceOnNavigation: true
    });
    Vue.use(VueGoodTablePlugin);