import { isObject } from '@/@core/utils/utils';
import axios from '@axios'
let userData = undefined;

if (localStorage.getItem('userData') !== "undefined") {
  userData = JSON.parse(localStorage.getItem('userData'))
}

export default {
  namespaced: true,
  state: {
    categories: [],
    cart: {},
    cartsNav: [],
    carts: [],
    cartItemsCount: (() => {

      return userData && userData.extras ? userData.extras.eCommerceCartItemsCount : 0
    })(),
  },
  getters: {},
  mutations: {
    UPDATE_CART_ITEMS_COUNT(state, count) {
      state.cartItemsCount = count
    },
    setCart(state, cart) {
      state.cart = cart
    },
    setCarts(state, carts) {
      state.carts = carts
    },
    setCartNav(state, carts) {
      state.cartsNav = carts
    },
    setCategories(state, categories) {
      state.categories = categories
    },
  },
  actions: {
    fetchCategories(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/categories', { params: payload })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchProducts(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/products', { params: payload })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProduct(ctx, { productId }) {

      var params = isObject(productId) ? { ...productId } : { productId };
      var productIdv = isObject(productId) ? productId.id : productId;

      return new Promise((resolve, reject) => {
        axios
          .get(`/products/${productIdv}`, { params: params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchWishlistProducts() {
      return new Promise((resolve, reject) => {
        axios
          .get('/wishlist')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCartProducts(ctx, params = {}) {
      return new Promise((resolve, reject) => {
        axios
          .get('/carts', { params: params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // ------------------------------------------------
    // Product Actions
    // ------------------------------------------------
    addProductInWishlist(ctx, { productId }) {
      var params = isObject(productId) ? { ...productId } : { productId };
      return new Promise((resolve, reject) => {
        axios
          .post('/wishlist', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeProductFromWishlist(ctx, { productId }) {
      var params = isObject(productId) ? { ...productId } : { productId };
      var productIdv = isObject(productId) ? productId.id : productId;
      return new Promise((resolve, reject) => {
        axios
          .delete(`/wishlist/${productIdv}`, { params: params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateQuantity(ctx, { params }) {


      return new Promise((resolve, reject) => {
        axios
          .post('/updateQuantity', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addProductInCart(ctx, { productId }) {

      var params = isObject(productId) ? { ...productId } : { productId };
      return new Promise((resolve, reject) => {
        axios
          .post('/carts', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeItemFromCart(ctx, { productId }) {
      var params = isObject(productId) ? { ...productId } : productId;
      var productIdv = isObject(productId) ? productId.id : productId;
      return new Promise((resolve, reject) => {
        axios
          .delete(`/removeItem/cart/${productIdv}`, { params: params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    removeProductFromCart(ctx, { productId }) {
      var params = isObject(productId) ? { ...productId } : productId;
      var productIdv = isObject(productId) ? productId.id : productId;
      return new Promise((resolve, reject) => {
        axios
          .delete(`/carts/${productIdv}`, { params: params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
