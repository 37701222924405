import Vue from 'vue'
//import store from '@/store'
// axios 
import { LogoutUser } from '@/auth/utils'
import axios from 'axios'
//const axios = require('axios').default;
//const base_url = process.env.MIX_API_URL
const base_url = `${APP_URL_API}/api/ws/`
// console.log(base_url)
const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: base_url,
  withCredentials: true,
  //origin: "local.posgamdif.com",
  // baseURL: 'http://127.0.0.1:8000/api/',
  // timeout: 1000,

  headers: {
    //  origin: "http://localhost:8080",
    //  methods: "GET,HEAD,PUT,PATCH,POST,DELETE",
    common: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
    }
  },

  // proxy: {
  //    protocol: 'http',
  //    host: "local.posgamdif.com",
  //    port: 9000,
  //    // auth: {
  //    //   username: 'mikeymike',
  //    //   password: 'rapunz3l'
  //    // }
  //  },

})



window.axios = axiosIns;
//window.axios.defaults.baseURL = '/api/';

window.axios.defaults.withCredentials = true;
window.axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken');

axiosIns.interceptors.response.use((response) => {
  return response;
}, error => {

  //
  if (error.response && error.response.data) {

    if (error.response.status === 401) {


      // store.dispatch("updateUserData");


      // store.dispatch("logout");
      LogoutUser();
      //   window.location.href = '/login';
      // window.history.pushState({},"",'/login');
      $rtr.push({ name: 'auth-login' });
    }
    if (error.response.status === 419) {

      //$str.dispatch("updateUserData");


      // store.dispatch("logout");
      // //  LogoutUser();
      //router.push({ name: 'login' });
    }
    if (error.response.status === 302) {
      //$str.dispatch("refreshUserPermissions");
      // router.push({ name: 'apps-dashboard' });
      //  window.location.href='/login';
      //  router.push({ name: 'auth-login' });
    }

    if (error.response.status === 404) {
      $rtr.push({ name: '404' });
      //  window.history.pushState({},"",'/404');
    }
    if (error.response.status === 403) {
      $rtr.push({ name: '403' });

    }

    return Promise.reject(error);
  }
  return Promise.reject(error);
});

Vue.prototype.$http = axiosIns

export default axiosIns
