import axios from '@axios'

export default {
  namespaced: true,
  state: {
    orders: [],
    order: {},
  },
  getters: {},
  mutations: {

    setOrderItem(state, order) {
      state.order = order
    },
  },
  actions: {
    fetchOrders(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/my/orders', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchOrder(ctx, { id, params = {} }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/my/orders/${id}`, { params: params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchClients() {
      return new Promise((resolve, reject) => {
        axios
          .get('/my/orders/clients')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },


    addOrder(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/my/orders', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateOrder(ctx, { id, params }) {
      return new Promise((resolve, reject) => {
        axios.patch(`/my/orders/${id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteOrder(ctx, orderId) {

      return new Promise((resolve, reject) => {
        axios
          .delete(`/my/orders/${orderId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
