import Vue from 'vue';
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { $t } from '@core/libs/i18n/utils.js'
//import Swal from 'vue-sweetalert2';
//import Swal from 'vue-sweetalert2';
import Swal from 'sweetalert2';
// const Toast = Swal.mixin({
//   toast: true,
//   position: 'top-end',
//   showConfirmButton: false,
//   timer: 3000,
//   timerProgressBar: true,
//   didOpen: (toast) => {
//     toast.addEventListener('mouseenter', Swal.stopTimer)
//     toast.addEventListener('mouseleave', Swal.resumeTimer)
//   }
// })

// Toast.fire({
//   icon: 'success',
//   title: 'Signed in successfully'
// })

window.Swal = Swal;
//window.Toast = Toast;
//import  { $t } from "@core/libs/i18n/utils.js";
let $makeToast = function (variant, msg, title) {

  // const toast = useToast()
  Vue.$toast(msg, {
    title: title,
    variant: variant,
    solid: true,
  });
  // toast({
  //   component: ToastificationContent,
  //   props: {
  //     title: msg,
  //     icon: 'AlertTriangleIcon',
  //     variant: 'danger',
  //   },
  // })
  // Toast.fire(msg, '', 'info')
}
// let $makeToast=function(variant, msg, title) {
//                 Vue.$toast(msg, {
//                     title: title,
//                     variant: variant,
//                     solid: true,
//                 });
//             }
Vue.prototype.$makeToast = $makeToast;

const $errorsres = function (error) {
  if (error.response) {
    let res = error.response;
    if (res.data && res.data.errors) {
      return res.data.errors;
    }
  }
  return {};

}

Vue.prototype.$errorsres = $errorsres;
let $viewAlert = function (msg, type = "success", timer = false, sh) {

  if (type == "success") {

    if (timer == true) {
      Swal.fire({
        icon: 'success',
        title: 'تنبية ',
        text: msg,
        showCancelButton: false,
        showConfirmButton: false,
        timer: 3000
      })
    } else {
      Swal.fire({
        icon: 'success',
        title: 'تنبية ',
        timerProgressBar: true,
        //   showLoaderOnConfirm: true,
        text: msg,
        html: msg,
        showCancelButton: false,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'نعم ',
        cancelButtonText: 'لا '
      });
    }


  } else {

    Swal.fire({
      title: "تنبيه ",
      text: msg,
      timerProgressBar: true,
      //showLoaderOnConfirm: true,
      cancelButtonColor: '#d33',
      confirmButtonText: 'موافق !',
      confirmButtonColor: '#ef4444',
      icon: 'error',
    });

  }

}

Vue.prototype.$viewAlert = $viewAlert;
let $responseSolve = function (response, useswal = true, type = "success", timer = false) {



  if (type == "success") {
    //  console.log('response',response)
    var msg = $t("lang.execute_successfully");
    var msg = "تم العملية بنجاح";
    if (response.data) {
      if (response.data.msg) {
        msg = response.data.msg;
      }

    }


    if (useswal == true) {



      $viewAlert(msg, type, timer);


      // timer: 2000
    }


    return msg;


  } else {


    /*if (error.response) {
         console.log('error response',error.response)
            // The client was given an error response (5xx, 4xx)
        } else if (error.request) {
              console.log('error request',error.request)
            // The client never received a response, and the request was never left
        } else {
            // Anything else
            console.log('error all',error)
        }*/

    var msg = $t("lang.InvalidData");
    var msg = "عفوا تحقق من البيانات";
    // console.log('error',response)
    let res = response.response;
    //console.log('res',res)
    if (res) {




      if (res.data && res.data.msg) {
        msg = res.data.msg;
      } else if (res.msg) {
        msg = res.msg;
      }
      if (useswal == true) {
        $viewAlert(msg, type, timer);
      }



    }

    return msg;
  }

}
Vue.prototype.$responseSolve = $responseSolve;
const $errorMsg = function getErrorMsg(error) {
  if (!error.response) {
    return false;
  }

  let result = error.response;
  if (error.response && error.response.data) {
    result = error.response.data;
  }
  let msg = "";
  if (error.message) {
    msg = error.message;
  }
  if (result.msg && result.msg.length > 0) {
    msg = result.msg;
  }
  return msg;
};
Vue.prototype.$errorMsg = $errorMsg

export {

  $responseSolve,
  $errorMsg,
  $viewAlert,
  $makeToast,
  $errorsres
}
// Vue.prototype.errorsHandler =function errorsHandler(error, formRef = "loginForm", return_error = false) {

//   if (!error.response) {
//     return false;
//   }

//   let result = error.response;
//   if (error.response && error.response.data) {
//     result = error.response.data;
//   }
//   //  console.log('result',result)
//   if (result.errors) {
//     //  self.errorsres = error.errors;
//     // console.log('error.errors',error.errors)
//     if (this.$refs[formRef]) {
//       this.$refs[formRef].setErrors(result.errors)
//     }
//     if (return_error == true) {
//       return result.errors;
//     }

//   }
//   let msg = "";
//   if (error.message) {
//     msg = error.message;
//   }
//   if (result.msg && result.msg.length > 0) {
//     msg = result.msg;
//   }
//   this.showToast(msg, 'danger')




// }