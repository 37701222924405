export default {


  updateSetting: (state, setting) => {
    state.settings = setting;
    state.setting_auth = setting.setting_auth ?? {};
    state.countresConfig = setting.countresConfig ?? [];
    state.defaultCountry = setting.defaultCountry ?? 'SA';
    state.countres_code = setting.countres_code ?? [];
  },
  clear(state) {
    state.settings = [];
  },


  setRecaptchaVerifierData(state, data) {
    state.recaptcha = data
  },
  setRecaptchaVerifierObject(state, data) {
    state.recaptchaVerifier = data
  },
  setPhoneData(state, data) {
    state.phoneObject = data
  },
  setSettingAuth(state, data) {
    state.setting_auth = data
  },

};
