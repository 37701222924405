export default [
  // *======================= pages  ========================---* //
  // *======================= pages  ========================---* //

  {
    path: '/404',
    name: '404',
    component: () => import('@/views/errors/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/not-authorized',
    name: '403',
    component: () => import('@/views/errors/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },

  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/auth/Register.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/auth/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/verify/:verfiy_token',
    name: 'auth-verify',
    component: () => import('@/views/auth/verify.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/complete/profile',
    name: 'complete-profile',
    component: () => import('@/views/auth/FinishedProfile.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },

  {
    path: '/reset-password-v1',
    name: 'auth-reset-password-v1',
    component: () => import('@/views/auth/ResetPassword-v1.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/coming-soon',
    name: 'misc-coming-soon',
    component: () => import('@/views/errors/ComingSoon.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/under-maintenance',
    name: 'misc-under-maintenance',
    component: () => import('@/views/errors/UnderMaintenance.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/error',
    name: 'misc-error',
    component: () => import('@/views/errors/Error.vue'),
    meta: {
      layout: 'full',
    },
  },
  // {
  //   path: '/urway/:uuid',
  //   name: 'urway-reponse',
  //   component: () => import('@/views/payment/responseurway.vue'),
  //   meta: {
  //     layout: 'full',
  //   },
  // },
  // {
  //   path: '/pages/account-setting',
  //   name: 'pages-account-setting',
  //   component: () => import('@/views/front/account-setting/AccountSetting.vue'),
  //   meta: {
  //     pageTitle: 'Account Settings',
  //     breadcrumb: [
  //       {
  //         text: 'Pages',
  //       },
  //       {
  //         text: 'Account Settings',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: '/my/profile',
    name: 'user.profile',
    component: () => import('@/views/front/account-setting/AccountSetting.vue'),
    //  component: () => import('@/views/front/profile/Profile.vue'),
    meta: {
      pageTitle: 'الملف الشخصى',
      breadcrumb: [

        {
          text: 'الملف الشخصى',
          active: true,
        },
      ],
    },
  }
]
