import Vue from 'vue'
import VueI18n from 'vue-i18n'

import LocalesTrans from '../../vue-i18n-locales.generated'

Vue.use(VueI18n)

const lang = document.documentElement.lang.substr(0, 2)
// console.log('lang', lang)

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  if (localStorage.getItem('lang') == null) {
    localStorage.setItem('lang', 'ar')
    localStorage.setItem('dir', 'rtl')
  }
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

const dictionary = {}
const messages = loadLocaleMessages()
for (const key in messages) {
// console.log("key",  messages[key]);
  const outt = Object.assign(messages[key], LocalesTrans[key])
  messages[key] = outt
}

export default new VueI18n({
  locale: localStorage.getItem('lang'),
  fallbackLocale: localStorage.getItem('lang'),
  messages,
})
