//require('./bootstrap')   
import Vue from 'vue'
import router from './router'
import store from './store'
import VueCompositionAPI from '@vue/composition-api'
import i18n from '@/libs/i18n'
window.$str = store;
window.$rtr = router;
window.$i18n = i18n;
store.dispatch('setting/Loadsettings');
// import ar from 'vee-validate/dist/locale/ar'
import Vuesax from 'vuesax'
import { getUserData, LogoutUser } from './auth/utils'

import App from './App.vue'
import 'vuesax/dist/vuesax.css'
// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
//import axios from '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'
import '@/libs/functions.js'
import '@/libs/soveAlert.js'

// Axios Mock Adapter
import '@/@fake-db/db'

//     localStorage.setItem('userData', JSON.stringify( {
//   id: 1,
//   fullName: 'John Doe',
//   username: 'johndoe',
//   password: 'password',
//   // eslint-disable-next-line global-require
//   avatar: require('@/assets/images/avatars/13-small.png'),
//   email: 'admin@cs.com',
//   role: 'admin',
//   ability: [
//     {
//       action: 'manage',
//       subject: 'all',
//     },
//   ],
//   extras: {
//     eCommerceCartItemsCount: 5,
//   },
// }))

//Vue.use(Vuesax)
const isRtl = store.state.appConfig.layout.isRTL

Vue.prototype.$isRtl = isRtl
Vue.prototype.$rtl = isRtl;
Vue.prototype.$dir = isRtl ? 'rtl' : 'ltr';
let colors = {
  primary: '#7367F0',
  success: '#28C76F',
  danger: '#EA5455',
  warning: '#FF9F43',
  dark: '#1E1E1E'
};
Vue.use(Vuesax, { rtl: isRtl })
Vue.component("breadcumb", () => import("./components/breadcumb"));


import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate';

//console.log("is_rtl",localStorage.getItem('userData'))

// localStorage.removeItem('userData')
// localStorage.removeItem('userAbilities')

import * as rules from "vee-validate/dist/rules";
import ar from 'vee-validate/dist/locale/ar.json'
import en from 'vee-validate/dist/locale/en.json'
if (i18n.locale == 'ar') {
  localize('ar', ar);
} else {
  localize('en', en);
}

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

// Register it globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
// const userData= JSON.parse(localStorage.getItem('userData')),

// const trans = (key) => {
//   return _.get(window.trans, key, key);
// }
// alert(trans('lang.home'))

//Vue.prototype.trans = key => _.get(window.trans, key, key)

// BSV Plugin Registration
// Vue.use(ToastPlugin)
// Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)
import VueTimeago from 'vue-timeago'
Vue.use(VueTimeago, {
  name: 'Timeago',
  locale: 'ar',
  locales: {
    ar: require('vue-timeago/node_modules/date-fns/locale/ar')
  }
})
// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@resources/scss/core.scss')

// import assets styles
require('@resources/assets/scss/style.scss')


import vSelect from 'vue-select'

//const vSelect = () => import('vue-select');
Vue.component('v-select', vSelect)

import 'vue-select/dist/vue-select.css';
// Vue.prototype.userData = JSON.parse(localStorage.getItem('userData'))
Vue.prototype.userData = getUserData() ? getUserData() : null
Vue.config.productionTip = true
Vue.config.devtools = true;

window.Fire = new Vue();

//  window.axios = axios;

// Vue.prototype.$http = axios 
// Vue.prototype.$http.interceptors.response.use((response) => {

//   return response;
// }, error => {
// //console.log('error home',error)
//  //
//   if (error.response) {
// //console.log('error home status',error.response.status)
//      if (error.response.status === 401) {


//      // store.dispatch("updateUserData");


//     // store.dispatch("logout");
//       LogoutUser();
//     //   window.location.href='/login';

//        router.push({ name: 'auth-login' });
//     }
//      if (error.response.status === 419) {

//       store.dispatch("updateUserData");


//      // store.dispatch("logout");
//       // //  LogoutUser();
//         //router.push({ name: 'login' });
//     }
//     if (error.response.status === 302) {
//      store.dispatch("refreshUserPermissions");
//        // router.push({ name: 'apps-dashboard' });
//     //  window.location.href='/login';
//     //  router.push({ name: 'auth-login' });
//     }

//     if (error.response.status === 404) {
//     router.push({ name: '404' });
//     }
//     if (error.response.status === 403) {
//        router.push({ name: '403' });
//     }

//     return Promise.reject(error);
//     return Promise.reject(error.response.data);
//   }
//   return Promise.reject(error);
//   return Promise.reject(error.message);
// });



new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
