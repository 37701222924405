export default {
    countres_code: [],
    settings: {},
    setting_auth: {},
    countresConfig: [],
    defaultCountry: "SA",
    phoneObject: {},
    recaptcha: null,
    recaptchaVerifier: null,
}
