export default {
    "ar": {
        "auth": {
            "failed": "أوراق الاعتماد هذه لا تتطابق مع سجلاتنا.",
            "throttle": "محاولات تسجيل دخول كثيرة جدًا. يرجى المحاولة مرة أخرى خلال: ثواني.",
            "full_name": "الاسم الكامل",
            "email": "البريد الاكتروني",
            "password": "كلمة المرور",
            "confirm_password": "تأكيد كلمة المرور",
            "remember_me": "ذكرنى",
            "sign_in": "تسجيل الدخول",
            "sign_out": "خروج",
            "register": "تسجيل",
            "login": "تسجيل الدخول",
            "registration": {
                "title": "تسجيل حساب جديدة",
                "i_agree": "أوافق على",
                "terms": "الشروط",
                "have_membership": "لدي حساب بالفعل"
            },
            "forgot_password": {
                "title": "أدخل البريد الإلكتروني لإعادة تعيين كلمة المرور",
                "send_pwd_reset": "إرسال رابط إعادة تعيين كلمة السر"
            },
            "reset_password": {
                "title": "اعد ضبط كلمه السر",
                "reset_pwd_btn": "إعادة تعيين كلمة المرور"
            },
            "emails": {
                "password": {
                    "reset_link": "انقر هنا لإعادة تعيين كلمة المرور الخاصة بك"
                }
            },
            "app": {
                "member_since": "عضو منذ",
                "messages": "الرسائل",
                "settings": "الاعدادات",
                "lock_account": "قفل الحساب",
                "profile": "البروفايل",
                "online": "متصل",
                "search": "Search",
                "create": "انشاء",
                "export": "استخراج",
                "print": "طباعة",
                "reset": "اعادة",
                "reload": "اعادة تحميل"
            },
            "Forgot_password": "هل نسيت كلمة السر؟",
            "do_you_have_account": "ليس لديك حساب؟",
            "create": "انشاء",
            "keep_me_signin": "احتفظ بتسجيل دخولي على هذا الجهاز",
            "sign_in_to_continue": "تسجيل الدخول للمتابعة.",
            "get_start": "مرحبا! هيا بنا نبدأ",
            "new_here": "جديد هنا؟",
            "sign_up_title": "التسجيل سهل. يستغرق الأمر بضع خطوات فقط",
            "have_account": "هل لديك حساب؟",
            "sign_up": "انشاء حساب",
            "your_account_does_not_activated": "عفوا حسابك غير مفعل!",
            "username_or_password_wrong": "اسم المستخدم أو كلمة المرور الخاصة بك خطأ!",
            "something_is_wrong": "هناك شئ غير صحيح!",
            "default_role_does_not_exist": "اوووه! الصلاحية الافتراضي غير موجود!",
            "account_created_successfully": "تم إنشاء حسابك بنجاح!",
            "placeholders": {
                "password": "كلمة المرور",
                "confirm_password": "تأكيد كلمة المرور",
                "username": "بريدك الالكتروني",
                "first_name": "الاسم الاول",
                "last_name": "الاسم الاخير"
            },
            "signup_success": "تم إنشاء المستخدم بنجاح!",
            "login_failed": "الوصول غير مرخص",
            "logout_success": "تم تسجيل الخروج بنجاح",
            "token_invalid": "رمز التنشيط هذا غير صالح.",
            "email_signup_activate_subject": "اكد حسابك",
            "email_signup_activate_line1": "شكرا على التسجيل! من فضلك قبل أن تبدأ ، يجب عليك تأكيد حسابك.",
            "email_signup_activate_action": "تأكيد الحساب",
            "email_signup_activate_line2": "شكرا لك على استخدام تطبيقنا!",
            "email_signup_activated_subject": "مرحبا بك في {appName}",
            "email_signup_activated_line1": "لقد أكملت تسجيلك! حسابك نشط.",
            "email_signup_activated_line2": "الآن يمكنك تسجيل الدخول."
        },
        "crud": {
            "add_new": "اضافة جديد",
            "cancel": "الغاء",
            "save": "حفظ",
            "edit": "تعديل",
            "detail": "التفاصيل",
            "back": "رجوع",
            "action": "الخيارات",
            "id": "Id",
            "created_at": "انشاء في",
            "updated_at": "تم التحديث في",
            "deleted_at": "تاريخ الحذف",
            "are_you_sure": "هل انت متأكد؟",
            "index": "الوصول",
            "create": "اضافة",
            "show": "عرض",
            "update": "تحديث",
            "store": "حفظ",
            "destroy": "حذف",
            "update_multi": "تعديل متعدد",
            "export": "الاستخراج",
            "print": "الطباعة",
            "permission": "الصلاحية",
            "permission_page": "اسم الصفحة",
            "accept": "قبول",
            "reject": "رفض"
        },
        "date": {
            "Sat": "السبت",
            "Sun": "الأحد",
            "Mon": "الإثنين",
            "Tue": "الثلاثاء",
            "Wed": "الأربعاء",
            "Thu": "الخميس",
            "Fri": "الجمعة",
            "Jan": "يناير",
            "Feb": "فبراير",
            "Mar": "مارس",
            "Apr": "أبريل",
            "May": "مايو",
            "Jun": "يونيو",
            "Jul": "يوليو",
            "Aug": "أغسطس",
            "Sep": "سبتمبر",
            "Oct": "أكتوبر",
            "Nov": "نوفمبر",
            "Dec": "ديسمبر"
        },
        "error": {
            "back": "الرجوع للخلف",
            "back_to_home": "الرجوع إلى الملف الشخصي",
            "page": {
                "not_found": "الصفحة غير موجودة"
            },
            "permission": "عفوا ليس لديك الصلاحية للقيام بهذه العملية",
            "store_disabled": "تم تعطيل التخزين"
        },
        "lang": {
            "direction": "rtl",
            "code": "ar",
            "lang": "AR",
            "left": "right",
            "right": "left",
            "rtl": "rtl",
            "ltr": "ltr",
            "boxCode": "ar",
            "boxCodeOther": "en",
            "calendarLanguage": "ar",
            "dashboard": "لوحة التحكم",
            "translations": "الترجمة",
            "eCommerce": "eCommerce",
            "Analytics": "تحليلات الزوار",
            "Apps": "Apps",
            "email": "البريد الاكتروني",
            "Chat": "الدردشة",
            "Todo": "Todo",
            "Calendar": "المهام والملاحظات",
            "Kanban": "Kanban",
            "Invoice List": "قائمة الفواتير",
            "Invoice Edit": "تعديل فاتورة",
            "Invoice Add": "اضافة الفاتورة",
            "File Manager": "File Manager",
            "UI": "UI",
            "Content": "المحتوى",
            "Grid": "Grid",
            "Typography": "Typography",
            "Text Utilities": "Text Utilities",
            "Syntax Highlighter": "Syntax Highlighter",
            "Helper Classes": "Helper Classes",
            "Colors": "الالوان",
            "Icons": "Icons",
            "BoxIcons": "BoxIcons",
            "LivIcons": "LivIcons",
            "Card": "شريحة",
            "Basic": "Basic",
            "Card Actions": "Card Actions",
            "Widgets": "Widgets",
            "Components": "Components",
            "Alerts": "Alerts",
            "Buttons": "Buttons",
            "Breadcrumbs": "Breadcrumbs",
            "Carousel": "Carousel",
            "Collapse": "Collapse",
            "Dropdowns": "Dropdowns",
            "List Group": "List Group",
            "Modals": "Modals",
            "Pagination": "Pagination",
            "Navbar": "Navbar",
            "Tabs Component": "Tabs Component",
            "Pills Component": "Pills Component",
            "Tooltips": "Tooltips",
            "Popovers": "Popovers",
            "Badges": "Badges",
            "Pill Badges": "Pill Badges",
            "Progress": "Progress",
            "Media Objects": "Media Objects",
            "Spinner": "Spinner",
            "Toasts": "Toasts",
            "Extra Components": "Extra Components",
            "Avatar": "Avatar",
            "Chips": "Chips",
            "Divider": "Divider",
            "Extensions": "Extensions",
            "Sweet Alert": "Sweet Alert",
            "Toastr": "Toastr",
            "NoUi Slider": "NoUi Slider",
            "Drag & Drop": "Drag & Drop",
            "Tour": "Tour",
            "swiper": "Swiper",
            "treeview": "Treeview",
            "Block-UI": "Block-UI",
            "Media Player": "Media Player",
            "Miscellaneous": "Miscellaneous",
            "i18n": "i18n",
            "Forms & Tables": "Forms & Tables",
            "Form Elements": "Form Elements",
            "input": "Input",
            "Input Groups": "Input Groups",
            "Number Input": "Number Input",
            "select": "اختيار",
            "radio": "Radio",
            "checkbox": "خانة اختيار",
            "switch": "Switch",
            "textarea": "Textarea",
            "Quill Editor": "Quill Editor",
            "File Uploader": "File Uploader",
            "Date & Time Picker": "Date & Time Picker",
            "Form Layout": "Form Layout",
            "Form Wizard": "Form Wizard",
            "Form Validation": "Form Validation",
            "Form Repeater": "Form Repeater",
            "table": "Table",
            "Table extended": "Table extended",
            "datatable": "Datatable",
            "Pages": "الصفحات",
            "User Profile": "User Profile",
            "FAQ": "الاسئلة  الشائعة",
            "Knowledge Base": "Knowledge Base",
            "search": "بحث",
            "Account Settings": "إعدادت الحساب",
            "user": "المستخدم",
            "list": "List",
            "view": "عرض ",
            "edit": "تعديل",
            "Starter kit": "Starter kit",
            "1 column": "1 column",
            "2 columns": "2 columns",
            "Fixed navbar": "Fixed navbar",
            "Fixed layout": "Fixed layout",
            "Static layout": "Static layout",
            "Authentication": "Authentication",
            "Login": "تسجيل الدخول",
            "Register": "Register",
            "Forgot Password": "Forgot Password",
            "Reset Password": "Reset Password",
            "Lock Screen": "Lock Screen",
            "Coming Soon": "Coming Soon",
            "Not Authorized": "Not Authorized",
            "Maintenance": "Maintenance",
            "Charts & Maps": "Charts & Maps",
            "Charts": "Charts",
            "Apex": "Apex",
            "Chartjs": "Chartjs",
            "Chartist": "Chartist",
            "Google Maps": "Google Maps",
            "Others": "Others",
            "Menu Levels": "Menu Levels",
            "Second Level": "Second Level",
            "Third Level": "Third Level",
            "Disabled Menu": "Disabled Menu",
            "Documentation": "Documentation",
            "Raise Support": "Raise Support",
            "Floating navbar": "Floating navbar",
            "Error": "خطأ",
            "Access Control": "Access Control",
            "home": "الرئيسية",
            "main": "رئيسي",
            "siteData": "بيانات الموقع",
            "settings": "الإعدادات",
            "visitorsAnalytics": "تحليلات الزوار",
            "visitorsAnalyticsBydate": "حسب التاريخ",
            "visitorsAnalyticsByCity": "المدينة",
            "visitorsAnalyticsByRegion": "المنطقة",
            "visitorsAnalyticsByAddress": "العنوان",
            "visitorsAnalyticsByCountry": "الدولة",
            "visitorsAnalyticsByOperatingSystem": "نظام التشغيل",
            "visitorsAnalyticsByBrowser": "المتصفح",
            "visitorsAnalyticsByScreenResolution": "دقة الشاشة",
            "visitorsAnalyticsByReachWay": "طريقة الوصول",
            "visitorsAnalyticsByHostName": "اسم المضيف",
            "visitorsAnalyticsByOrganization": "شركة الاتصال",
            "visitorsAnalyticsVisitorsHistory": "سجل الزوار",
            "visitorsAnalyticsIPInquiry": "استعلام عن IP",
            "visitorsAnalyticsLastVisit": "أخر زيارة",
            "members": "الأعضاء",
            "adsBanners": "البنرات الإعلانية",
            "siteInbox": "بريد الموقع",
            "newsletter": "النشرة البريدية",
            "generalSiteSettings": "الإعدادات العامة للموقع",
            "webmasterTools": "اعدادا  العامة",
            "generalSettings": "الإعدادات العامة",
            "siteSectionsSettings": "إعدادات أقسام الموقع",
            "adsBannersSettings": "إعدادات البنرات",
            "languageSettings": "إعدادات اللغة",
            "siteMenus": "قوائم الموقع",
            "usersPermissions": "المستخدمين والصلاحيات",
            "shoppingCart": "سلة التسوق",
            "orders": "الطلبات",
            "logout": "خروج",
            "checkAll": "تحديد الكل",
            "profile": "الملف الشخصي",
            "new": "إضافة",
            "add": "إضافة",
            "update": "حفظ التعديل",
            "save": "حفظ",
            "saveOrder": "حفظ الترتيب",
            "delete": "حذف",
            "undoDelete": "تراجع عن الحذف",
            "cancel": "إلغاء",
            "apply": "تنفيذ",
            "active": "فعال",
            "notActive": "غير مفعل",
            "status": "الحالة",
            "visits": "الزيارات",
            "contents": "المحتويات",
            "options": "خيارات",
            "showing": "عرض",
            "bulkAction": "الخيارات",
            "activeSelected": "تفعيل السجلات",
            "blockSelected": "حظر السجلات",
            "deleteSelected": "حذف السجلات",
            "of": "من أصل",
            "records": "سجلات",
            "confirmation": "تأكيد",
            "yes": "نعم",
            "no": "لا",
            "confirmationDeleteMsg": "هل أنت متأكد أنك تريد الحذف بالفعل؟",
            "sectionsOf": "تصنيفات ",
            "comments": "التعليقات",
            "videoTypes": "الأنواع المسموحة: mp4, ogv, webm",
            "imagesTypes": "الأنواع المسموحة: .png, .jpg, .jpeg, .gif",
            "audioTypes": "الأنواع المسموحة: .mp3, .wav",
            "attachTypes": "الأنواع المسموحة: .* الكل",
            "addDone": "تمت عملية الإضافة بنجاح",
            "saveDone": "تمت حفظ التعديلات التي أجريتها بنجاح",
            "deleteDone": "تمت حذف البيانات بنجاح",
            "erorr": "هناك خطأ برجاء إعادة المحاولة",
            "noData": "لا يوجد أية بيانات هنا حتى الآن",
            "appsSettings": "تفعيل التطبيقات",
            "fieldsSettings": "اعدادات الحقول",
            "frontSettings": "إعدادات الواجهة",
            "arabicLanguageFields": "تفعيل حقول اللغة العربية",
            "englishLanguageFields": "تفعيل حقول اللغة الإنجليزية",
            "seoTab": "تفعيل تبويب SEO",
            "seoTabTitle": "خيارات SEO",
            "defaultCurrency": "العملة الإفتراضية",
            "commentsStatus": "حالة التعليقات الجديدة",
            "automaticPublish": "تفعيل مباشر عند الإضافة",
            "manualByAdmin": "يدويا بعد المراجعة",
            "headerMenu": "قائمة الهيدر",
            "footerMenu": "قائمة الفوتر",
            "homeSlideBanners": "البنرات بالصفحة الرئيسية",
            "homeTextBanners": " البنرات النصية بالرئيسية",
            "sideBanners": "بنرات جانبية",
            "none": "None",
            "newsletterGroup": "المجموعة الخاصة بإيميلات النشرة البريدية",
            "homeRow1": "محتويات الرئيسة الصف الأول",
            "homeRow2": "محتويات الرئيسية الصف الثاني",
            "homeRow3": "أخر الموضوعات بالفوتر",
            "contactPageId": "صفحة اتصل بنا (التفاصيل والخرائط)",
            "activeLanguages": "اللغات المفعلة",
            "activeLanguages1": "لغة واحدة ( الإفتراضية في .env )",
            "activeLanguages2": "جميع اللغات",
            "homeBanners": "بنرات الرئيسية",
            "textBanners": "بنرات نصية",
            "sectionName": "عنوان القسم",
            "sectionType": "نوع القسم",
            "sectionNew": "إضافة قسم جديد",
            "sectionEdit": "تعديل القسم",
            "sectionIcon": "أيقونة",
            "sectionFather": "متفرع من",
            "sectionNoFather": "قسم رئيسي",
            "size": "المقاس",
            "width": "العرض",
            "height": "الإرتفاع",
            "descriptionBox": "حقل للوصف",
            "linkBox": "حقل لرابط عند الضغط",
            "sectionTypeCode": "كود / نص",
            "sectionTypePhoto": "صورة",
            "sectionTypeVideo": "فيديو",
            "langVar": "اسم متغير اللغة",
            "sitePages": "صفحات الموقع",
            "photos": "الصور",
            "blog": "المدونة",
            "services": "الخدمات",
            "news": "الأخبار",
            "videos": "الفيديو",
            "sounds": "الصوتيات",
            "products": "المنتجات",
            "typeTextPages": "صفحات عامة",
            "typePhotos": "صور",
            "typeVideos": "فيديوهات",
            "typeSounds": "صوتيات",
            "hasCategories": "يشمل تصنيفات",
            "reviewsAvailable": "تفعيل التعليقات",
            "dateField": "حقل تاريخ الإضافة",
            "longTextField": "حقل نص طويل",
            "allowEditor": "إستخدام المحرر",
            "attachFileField": "حقل ملف مرفق",
            "additionalImages": "صور إضافية",
            "attachGoogleMaps": "إرفاق خرائط جوجل",
            "attachOrderForm": "إرفاق نموذج للطلب",
            "withoutCategories": "بدون تصنيفات",
            "mainCategoriesOnly": "تصنيفات رئيسية فقط",
            "mainAndSubCategories": "تصنيفات رئيسية وفرعية",
            "sectionIconPicker": "إختيار أيقونة للقسم",
            "topicsIconPicker": "إختيار أيقونة للموضوعات",
            "iconPicker": "إختيار أيقونة",
            "siteInfoSettings": "بيانات الموقع",
            "siteStatusSettings": "حالة الموقع",
            "siteSocialSettings": "الشبكات الإجتماعية",
            "siteContactsSettings": "بيانات الإتصال",
            "websiteTitle": "عنوان الموقع",
            "metaDescription": "وصف الموقع",
            "metaKeywords": "كلمات دلالية",
            "websiteUrl": "رابط الموقع",
            "websiteNotificationEmail": "بريد استلام التلميحات",
            "websiteNotificationEmail1": "ارسل لي بريد في حالة رسائل جديدة",
            "websiteNotificationEmail2": "ارسل لي بريد في حالة تعليقات جديدة",
            "websiteNotificationEmail3": "ارسل لي بريد في حالة طلبات جديدة",
            "emailNotifications": "رسائل التنبيه",
            "contactAddress": "العنوان",
            "contactPhone": "الهاتف",
            "contactFax": "الفاكس",
            "contactMobile": "الموبايل",
            "contactEmail": "البريد الإلكتروني",
            "worksTime": "مواعيد العمل",
            "siteStatusSettingsPublished": "فعال للجميع",
            "siteStatusSettingsClosed": "مغلق",
            "siteStatusSettingsMsg": "رسالة في حالة الإغلاق",
            "facebook": "فيس بوك",
            "twitter": "تويتر",
            "google": "جوجل",
            "linkedin": "Linkedin",
            "youtube": "يوتيوب",
            "instagram": "Instagram",
            "pinterest": "Pinterest",
            "tumblr": "Tumblr",
            "flickr": "Flickr",
            "whatapp": "Whatsapp",
            "styleSettings": "خيارات التصميم",
            "siteLogo": "شعار الموقع",
            "favicon": "ايقونة المفضلة",
            "appleIcon": "ايقونة آبل",
            "styleColor1": "لون التصميم الأساسي",
            "styleColor2": "لون التصميم الثانوى",
            "restoreDefault": "إستعادة الإفتراضي",
            "layoutMode": "نظام القالب",
            "wide": "عرض كامل Wide",
            "boxed": "عرض محدد Boxed",
            "backgroundStyle": "نوع الخلفية",
            "colorBackground": "لون",
            "patternsBackground": "رسومي",
            "imageBackground": "صورة",
            "newsletterSubscribe": "الإشتراك في النشرة البريدية",
            "footerStyle": "تصميم الفوتر",
            "footerStyleBg": "خلفية الفوتر",
            "preLoad": "جاري التحميل",
            "bannerAdd": " إضافة بنر جديد",
            "bannerEdit": "تعديل البنر",
            "bannerTitle": "عنوان الإعلان",
            "bannerSection": "القسم",
            "bannerPhoto": "صورة الإعلان",
            "bannerDetails": "التفاصيل",
            "bannerLinkUrl": "رابط عند الضغط",
            "bannerVideoType": "نوع الفيديو",
            "bannerVideoType1": "فيديو مباشر",
            "bannerVideoType2": "فيديو من يوتيوب",
            "bannerVideoType3": "فيديو من فيميو",
            "bannerVideoFile": "ملف الفيديو",
            "bannerVideoUrl": "رابط الفيديو من يوتيوب",
            "bannerVideoUrl2": "رابط الفيديو من فيميو",
            "bannerCode": "كود البنر",
            "topicNew": "إضافة ",
            "topicEdit": "تعديل ",
            "topicName": " العنوان",
            "topicPhoto": "صورة",
            "topicSection": "القسم",
            "topicSelectSection": "حدد القسم",
            "topicDate": "التاريخ",
            "topicAudio": "الملف الصوتي",
            "topicVideo": "ملف الفيديو ",
            "topicAttach": "ملف مرفق",
            "topicDeletedSection": "غير تابع لأي قسم",
            "topicTabSection": "تفاصيل القسم",
            "topicTabDetails": "تفاصيل الموضوع",
            "topicSEOTitle": "عنوان الصفحة",
            "topicSEODesc": "الوصف",
            "topicSEOKeywords": "الكلمات الدلالية",
            "topicAdditionalPhotos": "صور إضافية",
            "topicGoogleMaps": "خرائط جوجل",
            "topicDropFiles": "ادرج الصور هنا أو انقر للتحميل",
            "topicDropFiles2": "يمكنك تحميل العديد من الصور في نفس الوقت",
            "topicCommentName": "الإسم",
            "topicCommentEmail": "البريد الإلكتروني",
            "topicComment": "التعليق",
            "topicNewComment": "تعليق جديد",
            "topicNewMap": "إضافة خريطة",
            "topicMapTitle": "العنوان",
            "topicMapDetails": "التفاصيل",
            "topicMapLocation": "الموقع",
            "topicMapIcon": "أيقونة",
            "topicMapClick": "إضغط علي المكان الذي تريد الإضافة فية",
            "topicMapORClick": "أو يمكنك الإضافة يدويا من هنا",
            "allContacts": "جميع المسجلين",
            "waitActivation": "بإنتظار التفعيل",
            "blockedContacts": "المسجلين المحظورين",
            "newGroup": "مجموعة جديدة",
            "newContacts": "إضافة حساب جديد",
            "editContacts": "تعديل الحساب",
            "deleteContacts": "حذف الحساب",
            "searchAllContacts": "بحث في جميع المسجلين",
            "firstName": "الإسم الأول",
            "lastName": "الإسم الأخير",
            "companyName": "اسم الشركة",
            "city": "المدينه",
            "notes": "ملاحظات",
            "group": "التصنيف",
            "sendEmail": "ارسال بريد",
            "callNow": "اتصل الآن",
            "selectFile": "حدد الملف",
            "labels": "أقسام أخرى",
            "inbox": "البريد الوارد",
            "sent": "المرسل",
            "draft": "المسودة",
            "compose": "رسالة جديدة",
            "makeAsRead": "اجعلها مقروءة",
            "makeAsUnread": "اجعلها غير مقروءة",
            "moveTo": "نقل إلى ",
            "replay": "رد على الرسالة",
            "forward": "إعادة توجيه",
            "sendTo": "إلى",
            "sendFrom": "من",
            "sendCc": "Cc",
            "sendBcc": "Bcc",
            "sendTitle": "عنوان الرسالة",
            "SaveToDraft": "حفظ بالمسودة",
            "send": "ارسال",
            "print": "طباعة",
            "AttachFiles": "ملفات مرفقة",
            "newEvent": "اضافة حدث / ملاحظة",
            "eventTotal": "الاجمالي",
            "eventTitle": "الحدث / الملاحظة ",
            "eventStart": "بداية الحدث",
            "eventEnd": "نهاية الحدث",
            "eventStart2": "تاريخ البداية",
            "eventEnd2": "تاريخ النهاية",
            "eventDetails": "التفاصيل",
            "eventNote": "ملاحظة",
            "eventMeeting": "موعد",
            "eventEvent": "حدث",
            "eventTask": "مهمة",
            "eventType": "النوع",
            "eventAt": "في",
            "eventToday": "اليوم",
            "eventDay": "عرض يومي",
            "eventWeek": "أسبوعي",
            "eventMonth": "شهري",
            "eventDelete": "حذف هذا الحدث / الملاحظة؟",
            "eventClear": "حذف جميع الملاحظات والأحداث",
            "diagram": "رسم توضيحي",
            "barDiagram": "عرض البيانات في شكل رسم بياني",
            "visitors": "عدد الزوار",
            "ip": "IP",
            "pageViews": "عدد الصفحات",
            "today": "اليوم",
            "yesterday": "أمس",
            "last7Days": "أخر 7 أيام",
            "last30Days": "أخر 30 يوم",
            "thisMonth": "الشهر الحالي",
            "lastMonth": "الشهر الماضي",
            "applyFrom": "من",
            "applyTo": "إلى",
            "customRange": "فترة مخصصة",
            "weekDays": "\"Su\", \"Mo\", \"Tu\", \"We\", \"Th\", \"Fr\", \"Sa\"",
            "monthsNames": "\"January\", \"February\", \"March\", \"April\", \"May\", \"June\", \"July\", \"August\", \"September\", \"October\", \"November\", \"December\"",
            "activity": "النشاط",
            "activitiesHistory": "سجل الأنشطة",
            "newUser": "إضافة مستخدم جديد",
            "editUser": "تعديل مستخدم",
            "fullName": "الإسم بالكامل",
            "permission": "الصلاحية",
            "permission1": "مسؤول الموقع",
            "permission2": "مدير الموقع ( جميع الصلاحيات )",
            "permission3": "مستخدم عادي ( بدون حذف )",
            "permission4": "مشرف/مراجع ( عرض فقط )",
            "personalPhoto": "صورة شخصية",
            "loginEmail": "البريد الإلكتروني",
            "loginPassword": "كلمة المرور",
            "users": "المستخدمين",
            "permissions": "الصلاحيات",
            "newPermissions": "إضافة صلاحية جديدة",
            "title": "العنوان",
            "dataManagements": "إدارة المحتوى",
            "dataManagements1": "المحتوى الخاص به فقط (للعضويات الشخصية فقط)",
            "dataManagements2": "كافة المحتويات من جميع المستخدمين (لعضويات الإدارة العامة)",
            "activeApps": "التطبيقات المفعلة",
            "activeSiteSections": "أقسام الموقع المفعلة",
            "addPermission": "صلاحية الإضافة",
            "editPermission": "صلاحية التعديل",
            "deletePermission": "صلاحية الحذف",
            "viewOnly": "مشاهدة فقط",
            "editPermissions": "تعديل الصلاحيات",
            "perAdd": "الإضافة",
            "perEdit": "التعديل",
            "perDelete": "الحذف",
            "selectPermissionsType": "حدد نوع الصلاحية",
            "newLink": "اضافة رابط جديد",
            "newMenu": "اضافة قائمة جديدة",
            "menuTitle": "اسم القائمة",
            "editSection": "تعديل رابط",
            "linkType": "نوع الرابط",
            "linkType1": "عنوان رئيسي",
            "linkType2": "رابط مباشر",
            "linkType3": "قسم رئيسي",
            "linkType4": "قائمة منسدلة لقسم",
            "linkURL": "الرابط",
            "linkSection": "قسم الرابط",
            "sectionTitle": "عنوان القسم / الرابط",
            "fatherSection": "القسم الرئيسي",
            "latestMessages": "أحدث الرسائل",
            "notesEvents": "الملاحظات والأحداث",
            "latestContacts": "جديد جهات الإتصال",
            "more": "المزيد",
            "viewMore": "مشاهدة المزيد",
            "addNew": "إضافة",
            "reports": "التقارير",
            "reportsDetails": "يمكنك مشاهدة المزيد من التقارير",
            "hi": "أهلاً",
            "welcomeBack": "مرحباً بعودتك",
            "lastFor7Days": "الزوار لأخر ٧ أيام",
            "todayByCountry": "زوار اليوم حسب الدولة",
            "browsers": "متصفح الإنترنت",
            "browsersCalculated": "نسبة المتصفحات حسب أخر ٧ أيام",
            "visitorsRate": "معدل الزوار",
            "visitorsRateToday": "معدل الزوار على مدار اليوم الحالي",
            "sections": "الاقسام",
            "resultsFoundFor": "نتائج البحث عن",
            "connectEmailToConnect": "ربط مع البريد الإلكتروني",
            "connectEmail": "البريد الإلكتروني",
            "connectPassword": "كلمة المرور",
            "openWebmail": "افتح البريد الإلكتروني",
            "themeSwitcher": "التصميم واللغة",
            "foldedAside": "تصغير القائمة الجانة",
            "boxedLayout": "تصغير عرض النافذة",
            "themes": "التصاميم",
            "themes1": "فاتح",
            "themes2": "رمادي",
            "themes3": "غامق",
            "themes4": "أسود",
            "language": "اللغة",
            "change": "حفظ التغييرات",
            "sitePreview": " معاينة الموقع",
            "oops": "عفوا",
            "export": "تصدير",
            "close": "اغلاق",
            "columns": "الاعمده",
            "wallets": "المحفظة",
            "pending": "قيد الانتظار",
            "failed": "فشل",
            "canceled": "ملغي",
            "execute_successfully": "تم العملية بنجاح",
            "Success": "مكتملة",
            "Refund": "مرتجع",
            "Cancelled": "ملغية",
            "invoice": "الفاتورة",
            "invoice_plural": "الفواتير",
            InvalidData: 'بيانات غير صالحة',
            "id": "معرف",
            "business_id": "معرف العمل",
            "type": "النوع",
            "supplier_business_name": "اسم العمل المورد",
            "name": "الاسم",
            "prefix": "البادئة",
            "first_name": "الاسم الأول",
            "middle_name": "الاسم الأوسط",
            "last_name": "الاسم الأخير",
            "email": "البريد الإلكتروني",
            "contact_id": "معرف جهة الاتصال",
            "contact_status": "حالة جهة الاتصال",
            "tax_number": "الرقم الضريبي",
            "city": "المدينة",
            "state": "الولاية",
            "country": "البلد",
            "address_line_1": "العنوان الأول",
            "address_line_2": "العنوان الثاني",
            "zip_code": "الرمز البريدي",
            "dob": "تاريخ الميلاد",
            "mobile": "الجوال",
            "landline": "الهاتف الأرضي",
            "alternate_number": "رقم بديل",
            "pay_term_number": "عدد مصطلح الدفع",
            "pay_term_type": "نوع مصطلح الدفع",
            "credit_limit": "الحد الائتماني",
            "created_by": "تم الإنشاء بواسطة",
            "converted_by": "تم التحويل بواسطة",
            "converted_on": "تم التحويل في",
            "balance": "الرصيد",
            "total_rp": "مجموع نقاط الولاء",
            "total_rp_used": "إجمالي نقاط الولاء المستخدمة",
            "total_rp_expired": "إجمالي نقاط الولاء المنتهية الصلاحية",
            "is_default": "الافتراضي",
            "shipping_address": "عنوان الشحن",
            "shipping_custom_field_details": "تفاصيل حقل مخصص للشحن",
            "is_export": "تصدير",
            "export_custom_field_1": "حقل مخصص للتصدير 1",
            "export_custom_field_2": "حقل مخصص للتصدير 2",
            "export_custom_field_3": "حقل مخصص للتصدير 3",
            "export_custom_field_4": "حقل مخصص للتصدير 4",
            "id": "معرف",
            "user_type": "نوع المستخدم",
            "contact_type": "نوع الاتصال",
            "name": "الاسم",
            "surname": "اللقب",
            "username": "اسم المستخدم",
            "email": "البريد الإلكتروني",
            "avatar": "الصورة الرمزية",
            "photo": "الصورة",
            "address": "العنوان",
            "country": "البلد",
            "city": "المدينة",
            "postal_code": "الرمز البريدي",
            "phone": "الهاتف",
            "balance": "الرصيد",
            "crm_contact_id": "معرف جهة الاتصال في نظام إدارة العلاقات بالعملاء",
            "group_id": "معرف المجموعة",
            "location_id": "معرف الموقع",
            "current_address": "العنوان الحالي",
            "contact_no": "رقم الاتصال",
        },
        "messages": {
            "retrieved": "{model} تم استرجاعه بنجاح.",
            "saved": " تم اضافة  {model} بنجاح. ",
            "updated": " تم التحديث  {model} بنجاح.  ",
            "deleted": "{model} تم عملية الحذف بنجاح",
            "not_found": "{model} غير موجود",
            "duplicate_entry": "{model} عفوا هذه البيانات موجود مسبقاً ",
            "foreign_entry": "{model}  عفوا لا يمكنك حذف هذه البيانات لكونها مرتبطة ببيانات اخرى.",
            "purchase_from": "شراء من  {marketplace}",
            "send_invoice_to_customer": "أرسل الفاتورة",
            "message_to_customer": "رسالة للعميل"
        },
        "notifications": {
            "package_subscriber_notif": "يوجد طلب اشتراك في الباقة {package} من التاجر {name}",
            "invoice_message_change_status": "تم تحديث  الفاتروة الخاصة بك  {uuid}  ({status})",
            "accept_opinv": "تم القبول",
            "new_order": "أمر شراء جديد",
            "from_client": "من العميل",
            "from_supplier": "من التاجر",
            "reject_appointment": "رفض  أمر الشراء",
            "your_order": "أمرالشراء الخاص بك",
            "orders_message_change_status": "تم تحديث أمر الشراء الخاصة بك  {uuid}  ({status})",
            "accept_order": "تم الموافقة على الطلب",
            "order_message_change_status": "تم تحديث الطلب الخاصة بك  {uuid}  ({status})"
        },
        "pagination": {
            "previous": "السابق",
            "next": "التالي"
        },
        "passwords": {
            "reset": "تم إعادة تعيين كلمة المرور الخاصة بك!",
            "sent": "لقد أرسلنا رابط إعادة تعيين كلمة المرور بالبريد الإلكتروني!",
            "throttled": "الرجاء الانتظار قبل إعادة المحاولة.",
            "token": "رمز إعادة تعيين كلمة المرور هذا غير صالح.",
            "user": "لا يمكننا العثور على مستخدم بعنوان البريد الإلكتروني هذا.",
            "email_password_reset_request_subject": "اعد ضبط كلمه السر",
            "email_password_reset_request_line1": "لقد طلبت إعادة تعيين كلمة المرور لحسابك.",
            "email_password_reset_request_action": "إعادة تعيين كلمة المرور",
            "email_password_reset_request_line2": "إذا لم تطلب إعادة تعيين كلمة المرور ، فلا داعي لاتخاذ أي إجراء آخر.",
            "email_password_reset_success_subject": "تم تغيير كلمة السر الخاصة بك",
            "email_password_reset_success_line1": "تم تغيير كلمة المرور الخاصة بك لحسابك.",
            "email_password_reset_success_line2": "إذا قمت بتغيير كلمة المرور ، فلا داعي لاتخاذ أي إجراء آخر.",
            "email_password_reset_success_line3": "إذا لم تقم بتغيير كلمة المرور ، فقم بحماية حسابك."
        },
        "transaction": {
            "statement_session_transaction": "مقابل تسديد رسوم جلسة ({appointment})  المستشار /  {consultant}",
            "statement_session_refund_transaction": "مقابل  ارجاع  رسوم جلسة ({appointment})  من المستشار /  {consultant}",
            "statement_consultantor_transaction": "مقابل  رسوم جلسة ({appointment})  للزائر   {client}",
            "statement_consultantor_refund_transaction": "مقابل خصم رسوم جلسة ({appointment})  الملغية للزائر  {client}",
            "wallet_debit_message": "تم خصم  من حسابك مبلغ {amount} , رصيدك {balance}",
            "debit_opreation": "عملية سحب",
            "wallet_credit_message": "تم الايداع في حسابك مبلغ {amount} , رصيدك {balance}",
            "credit_opreation": "عملية ايداع",
            "request_fees_transaction": "مقابل تسديد رسوم  {request} ({uuid})",
            "request_fees_transaction_doctor": "مقابل  رسوم  {request} ({id})",
            "statement_refund_many_request": "مقابل  ارجاع  رسوم جلسة  {request} ({id})"
        },
        "validation": {
            "attributes": {
                "name": "الاسم ",
                "username": "اسم  المستخدم ",
                "email": "البريد  الاكتروني ",
                "first_name": "الاسم الاول",
                "last_name": "الاسم الاخير",
                "password": "كلمة  المرور ",
                "password_confirmation": "تاكيد كلمة المرو",
                "city": "المدينة",
                "country": "البلد",
                "address": "العنوان",
                "phone": "الهاتف",
                "mobile": "رقم الجوال ",
                "age": "العمر",
                "sex": "الجنس",
                "gender": "الجنس",
                "day": "اليوم",
                "month": "الشهر",
                "year": "السنة",
                "hour": "الساعة",
                "minute": "الدقيقة",
                "second": "الثانية",
                "title": "العنوان",
                "content": "المحتوى",
                "description": "الوصف",
                "excerpt": "الاقتتطاف",
                "date": "التاريخ",
                "time": "الزمن",
                "available": "متوفر",
                "size": "الحجم",
                "body": "المحتوى",
                "subject": "الموضوع",
                "message": "الرسالة"
            },
            "accepted": "يجب قبول {attribute}.",
            "active_url": "{attribute} لا يُمثّل رابطًا صحيحًا.",
            "after": "يجب على {attribute} أن يكون تاريخًا لاحقًا للتاريخ {date}.",
            "after_or_equal": "{attribute} يجب أن يكون تاريخاً لاحقاً أو مطابقاً للتاريخ {date}.",
            "alpha": "يجب أن لا يحتوي {attribute} سوى على حروف.",
            "alpha_dash": "يجب أن لا يحتوي {attribute} سوى على حروف، أرقام ومطّات.",
            "alpha_num": "يجب أن يحتوي {attribute} على حروفٍ وأرقامٍ فقط.",
            "array": "يجب أن يكون {attribute} ًمصفوفة.",
            "before": "يجب على {attribute} أن يكون تاريخًا سابقًا للتاريخ {date}.",
            "before_or_equal": "{attribute} يجب أن يكون تاريخا سابقا أو مطابقا للتاريخ {date}.",
            "between": {
                "numeric": "يجب أن تكون قيمة {attribute} بين {min} و {max}.",
                "file": "يجب أن يكون حجم الملف {attribute} بين {min} و {max} كيلوبايت.",
                "string": "يجب أن يكون عدد حروف النّص {attribute} بين {min} و {max}.",
                "array": "يجب أن يحتوي {attribute} على عدد من العناصر بين {min} و {max}."
            },
            "boolean": "يجب أن تكون قيمة {attribute} إما true أو false .",
            "confirmed": "حقل التأكيد غير مُطابق للحقل {attribute}.",
            "date": "{attribute} ليس تاريخًا صحيحًا.",
            "date_equals": "يجب أن يكون {attribute} مطابقاً للتاريخ {date}.",
            "date_format": "لا يتوافق {attribute} مع الشكل {format}.",
            "different": "يجب أن يكون الحقلان {attribute} و {other} مُختلفين.",
            "digits": "يجب أن يحتوي {attribute} على {digits} رقمًا/أرقام.",
            "digits_between": "يجب أن يحتوي {attribute} بين {min} و {max} رقمًا/أرقام .",
            "dimensions": "الـ {attribute} يحتوي على أبعاد صورة غير صالحة.",
            "distinct": "للحقل {attribute} قيمة مُكرّرة.",
            "email": "يجب أن يكون {attribute} عنوان بريد إلكتروني صحيح البُنية.",
            "ends_with": "يجب أن ينتهي {attribute} بأحد القيم التالية: {values}",
            "exists": "{attribute}  موجود مسبقاً ",
            "file": "الـ {attribute} يجب أن يكون ملفا.",
            "filled": "{attribute} إجباري.",
            "gt": {
                "numeric": "يجب أن تكون قيمة {attribute} أكبر من {value}.",
                "file": "يجب أن يكون حجم الملف {attribute} أكبر من {value} كيلوبايت.",
                "string": "يجب أن يكون طول النّص {attribute} أكثر من {value} حروفٍ/حرفًا.",
                "array": "يجب أن يحتوي {attribute} على أكثر من {value} عناصر/عنصر."
            },
            "gte": {
                "numeric": "يجب أن تكون قيمة {attribute} مساوية أو أكبر من {value}.",
                "file": "يجب أن يكون حجم الملف {attribute} على الأقل {value} كيلوبايت.",
                "string": "يجب أن يكون طول النص {attribute} على الأقل {value} حروفٍ/حرفًا.",
                "array": "يجب أن يحتوي {attribute} على الأقل على {value} عُنصرًا/عناصر."
            },
            "image": "يجب أن يكون {attribute} صورةً.",
            "in": "{attribute} غير موجود.",
            "in_array": "{attribute} غير موجود في {other}.",
            "integer": "يجب أن يكون {attribute} عددًا صحيحًا.",
            "ip": "يجب أن يكون {attribute} عنوان IP صحيحًا.",
            "ipv4": "يجب أن يكون {attribute} عنوان IPv4 صحيحًا.",
            "ipv6": "يجب أن يكون {attribute} عنوان IPv6 صحيحًا.",
            "json": "يجب أن يكون {attribute} نصًا من نوع JSON.",
            "lt": {
                "numeric": "يجب أن تكون قيمة {attribute} أصغر من {value}.",
                "file": "يجب أن يكون حجم الملف {attribute} أصغر من {value} كيلوبايت.",
                "string": "يجب أن يكون طول النّص {attribute} أقل من {value} حروفٍ/حرفًا.",
                "array": "يجب أن يحتوي {attribute} على أقل من {value} عناصر/عنصر."
            },
            "lte": {
                "numeric": "يجب أن تكون قيمة {attribute} مساوية أو أصغر من {value}.",
                "file": "يجب أن لا يتجاوز حجم الملف {attribute} {value} كيلوبايت.",
                "string": "يجب أن لا يتجاوز طول النّص {attribute} {value} حروفٍ/حرفًا.",
                "array": "يجب أن لا يحتوي {attribute} على أكثر من {value} عناصر/عنصر."
            },
            "max": {
                "numeric": "يجب أن تكون قيمة {attribute} مساوية أو أصغر من {max}.",
                "file": "يجب أن لا يتجاوز حجم الملف {attribute} {max} كيلوبايت.",
                "string": "يجب أن لا يتجاوز طول النّص {attribute} {max} حروفٍ/حرفًا.",
                "array": "يجب أن لا يحتوي {attribute} على أكثر من {max} عناصر/عنصر."
            },
            "mimes": "يجب أن يكون ملفًا من نوع : {values}.",
            "mimetypes": "يجب أن يكون ملفًا من نوع : {values}.",
            "min": {
                "numeric": "يجب أن تكون قيمة {attribute} مساوية أو أكبر من {min}.",
                "file": "يجب أن يكون حجم الملف {attribute} على الأقل {min} كيلوبايت.",
                "string": "يجب أن يكون طول النص {attribute} على الأقل {min} حروفٍ/حرفًا.",
                "array": "يجب أن يحتوي {attribute} على الأقل على {min} عُنصرًا/عناصر."
            },
            "not_in": "العنصر {attribute} غير صحيح.",
            "not_regex": "صيغة {attribute} غير صحيحة.",
            "numeric": "يجب على {attribute} أن يكون رقمًا.",
            "password": "كلمة المرور غير صحيحة.",
            "present": "يجب تقديم {attribute}.",
            "regex": "صيغة {attribute} .غير صحيحة.",
            "required": "{attribute} حقل مطلوب .",
            "required_if": "{attribute} مطلوب في حال ما إذا كان {other} يساوي {value}.",
            "required_unless": "{attribute} مطلوب في حال ما لم يكن {other} يساوي {values}.",
            "required_with": "{attribute} مطلوب إذا توفّر {values}.",
            "required_with_all": "{attribute} مطلوب إذا توفّر {values}.",
            "required_without": "{attribute} مطلوب إذا لم يتوفّر {values}.",
            "required_without_all": "{attribute} مطلوب إذا لم يتوفّر {values}.",
            "same": "يجب أن يتطابق {attribute} مع {other}.",
            "size": {
                "numeric": "يجب أن تكون قيمة {attribute} مساوية لـ {size}.",
                "file": "يجب أن يكون حجم الملف {attribute} {size} كيلوبايت.",
                "string": "يجب أن يحتوي النص {attribute} على {size} حروفٍ/حرفًا بالضبط.",
                "array": "يجب أن يحتوي {attribute} على {size} عنصرٍ/عناصر بالضبط."
            },
            "starts_with": "يجب أن يبدأ {attribute} بأحد القيم التالية: {values}",
            "string": "يجب أن يكون {attribute} نصًا.",
            "timezone": "يجب أن يكون {attribute} نطاقًا زمنيًا صحيحًا.",
            "unique": "{attribute}موجود مسبقاً .",
            "uploaded": "فشل في تحميل الـ {attribute}.",
            "url": "صيغة الرابط {attribute} غير صحيحة.",
            "uuid": "{attribute} يجب أن يكون بصيغة UUID سليمة."
        }
    },
    "en": {
        "auth": {
            "failed": "These credentials do not match our records.",
            "throttle": "Too many login attempts. Please try again in {seconds} seconds.",
            "full_name": "Full Name",
            "email": "Email",
            "password": "Password",
            "confirm_password": "Confirm Password",
            "remember_me": "Remember Me",
            "sign_in": "SIGN IN",
            "sign_out": "Sign out",
            "register": "Register",
            "login": "Login",
            "registration": {
                "title": "Register a new membership",
                "i_agree": "I agree to",
                "terms": "the terms",
                "have_membership": "I already have a membership"
            },
            "forgot_password": {
                "title": "Enter Email to reset password",
                "send_pwd_reset": "Send Password Reset Link"
            },
            "reset_password": {
                "title": "Reset your password",
                "reset_pwd_btn": "Reset Password"
            },
            "emails": {
                "password": {
                    "reset_link": "Click here to reset your password"
                }
            },
            "app": {
                "member_since": "Member since",
                "messages": "Messages",
                "settings": "Settings",
                "lock_account": "Lock Account",
                "profile": "Profile",
                "online": "Online",
                "search": "Search",
                "create": "Create",
                "export": "Export",
                "print": "Print",
                "reset": "Reset",
                "reload": "Reload"
            },
            "Forgot_password": "Forgot password?",
            "do_you_have_account": "Don't have an account?",
            "create": "Create",
            "keep_me_signin": "Keep me signed in",
            "sign_in_to_continue": "Sign in to continue.",
            "get_start": "Hello! let's get started",
            "new_here": "New here?",
            "sign_up_title": "Signing up is easy. It only takes a few steps",
            "have_account": "Already have an account?",
            "sign_up": "SIGN UP",
            "your_account_does_not_activated": "Your account does not activated!",
            "username_or_password_wrong": "Your username or password is wrong!",
            "something_is_wrong": "Something is wrong!",
            "default_role_does_not_exist": "Ooops! Default role does not exist!",
            "account_created_successfully": "Your account created successfully!",
            "placeholders": {
                "password": "Password",
                "confirm_password": "Password Confirmation",
                "username": "Your Email",
                "first_name": "First Name",
                "last_name": "Last Name"
            },
            "signup_success": "Successfully created user!",
            "login_failed": "Unauthorized",
            "logout_success": "Successfully logged out",
            "token_invalid": "This activation token is invalid.",
            "email_signup_activate_subject": "Confirm your account",
            "email_signup_activate_line1": "Thanks for signup! Please before you begin, you must confirm your account.",
            "email_signup_activate_action": "Confirm Account",
            "email_signup_activate_line2": "Thank you for using our application!",
            "email_signup_activated_subject": "Welcome to {appName}",
            "email_signup_activated_line1": "You have completed your registration! your account is active.",
            "email_signup_activated_line2": "Now you can be login."
        },
        "crud": {
            "add_new": "Add New",
            "cancel": "Cancel",
            "save": "Save",
            "edit": "Edit",
            "detail": "Detail",
            "back": "Back",
            "action": "Action",
            "id": "Id",
            "created_at": "Created At",
            "updated_at": "Updated At",
            "deleted_at": "Deleted At",
            "are_you_sure": "Are you sure?",
            "index": "index of ",
            "create": "create ",
            "show": "show of ",
            "update": "update ",
            "store": "store ",
            "destroy": "destroy",
            "update_multi": "update multi",
            "export": "export",
            "print": "print",
            "permission": "permission",
            "permission_page": "permission page",
            "accept": "accept",
            "reject": "reject"
        },
        "date": {
            "Sat": "Saturday",
            "Sun": "Sunday",
            "Mon": "Monday",
            "Tue": "Tuesday",
            "Wed": "Wednesday",
            "Thu": "Thursday",
            "Fri": "Friday",
            "Jan": "January",
            "Feb": "February",
            "Mar": "March",
            "Apr": "April",
            "May": "May",
            "Jun": "June",
            "Jul": "July",
            "Aug": "August",
            "Sep": "September",
            "Oct": "October",
            "Nov": "November",
            "Dec": "December"
        },
        "error": {
            "back": "Go Back",
            "back_to_home": "Back to profile",
            "page": {
                "not_found": "Page not found"
            },
            "permission": "User does not have the permission",
            "store_disabled": "store_disabled"
        },
        "lang": {
            "direction": "ltr",
            "code": "en",
            "lang": "EN",
            "left": "left",
            "right": "right",
            "rtl": "rtl",
            "ltr": "ltr",
            "boxCode": "en",
            "boxCodeOther": "ar",
            "translations": "Translations",
            "dashboard": "Dashboard",
            "email": "Email",
            "home": "Home",
            "main": "Main",
            "siteData": "Site Data",
            "settings": "Settings"
        },
        "messages": {
            "retrieved": "{model} retrieved successfully.",
            "saved": "{model} saved successfully.",
            "updated": "{model} updated successfully.",
            "deleted": "{model} deleted successfully.",
            "not_found": "{model} not found",
            "duplicate_entry": "{model} Sorry, this data already exists ",
            "foreign_entry": "{model} Sorry, you cannot delete this data as it is related to other data."
        },
        "notifications": {
            "package_subscriber_notif": "There is a request to subscribe to the package {package} from the merchant {name}",
            "invoice_message_change_status": "Your invoice has been updated {uuid} ({status})",
            "accept_opinv": "accept Invoice",
            "new_order": "أمر شراء جديد",
            "from_client": "من العميل",
            "from_supplier": "من العميل",
            "reject_appointment": "رفض  الطلب",
            "your_order": "أمرالشراء الخاص بك",
            "orders_message_change_status": "تم تحديث أمر الشراء الخاصة بك  {uuid}  ({status})",
            "accept_order": "تم الموافقة على الطلب",
            "order_message_change_status": "تم تحديث الطلب الخاصة بك  {uuid}  ({status})"
        },
        "pagination": {
            "previous": "&laquo; Previous",
            "next": "Next &raquo;"
        },
        "passwords": {
            "reset": "Your password has been reset!",
            "sent": "We have e-mailed your password reset link!",
            "throttled": "Please wait before retrying.",
            "token": "This password reset token is invalid.",
            "user": "We can't find a user with that e-mail address.",
            "email_password_reset_request_subject": "Reset your password",
            "email_password_reset_request_line1": "You have requested to reset the password for your account.",
            "email_password_reset_request_action": "Reset Password",
            "email_password_reset_request_line2": "If you did not request a password reset, no further action is required.",
            "email_password_reset_success_subject": "Your password has been changed",
            "email_password_reset_success_line1": "Your password has been changed for your account.",
            "email_password_reset_success_line2": "If you did change password, no further action is required.",
            "email_password_reset_success_line3": "If you did not change password, protect your account."
        },
        "transaction": {
            "statement_session_transaction": "مقابل تسديد رسوم جلسة ({appointment})  المستشار /  {consultant}",
            "statement_session_refund_transaction": "مقابل  ارجاع  رسوم جلسة ({appointment})  من المستشار /  {consultant}",
            "statement_consultantor_transaction": "مقابل  رسوم جلسة ({appointment})  للزائر   {client}",
            "statement_consultantor_refund_transaction": "مقابل خصم رسوم جلسة ({appointment})  الملغية للزائر  {client}",
            "wallet_debit_message": "تم خصم  من حسابك مبلغ {amount} , رصيدك {balance}",
            "debit_opreation": "عملية سحب",
            "wallet_credit_message": "تم الايداع في حسابك مبلغ {amount} , رصيدك {balance}",
            "credit_opreation": "عملية ايداع",
            "request_fees_transaction": "مقابل تسديد رسوم  {request} ({id})",
            "request_fees_transaction_doctor": "مقابل  رسوم  {request} ({id})",
            "statement_refund_many_request": "For a session fee refund: request ({id})"
        },
        "validation": {
            "accepted": "The {attribute} must be accepted.",
            "active_url": "The {attribute} is not a valid URL.",
            "after": "The {attribute} must be a date after {date}.",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}.",
            "alpha": "The {attribute} must only contain letters.",
            "alpha_dash": "The {attribute} must only contain letters, numbers, dashes and underscores.",
            "alpha_num": "The {attribute} must only contain letters and numbers.",
            "array": "The {attribute} must be an array.",
            "before": "The {attribute} must be a date before {date}.",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}.",
            "between": {
                "numeric": "The {attribute} must be between {min} and {max}.",
                "file": "The {attribute} must be between {min} and {max} kilobytes.",
                "string": "The {attribute} must be between {min} and {max} characters.",
                "array": "The {attribute} must have between {min} and {max} items."
            },
            "boolean": "The {attribute} field must be true or false.",
            "confirmed": "The {attribute} confirmation does not match.",
            "date": "The {attribute} is not a valid date.",
            "date_equals": "The {attribute} must be a date equal to {date}.",
            "date_format": "The {attribute} does not match the format {format}.",
            "different": "The {attribute} and {other} must be different.",
            "digits": "The {attribute} must be {digits} digits.",
            "digits_between": "The {attribute} must be between {min} and {max} digits.",
            "dimensions": "The {attribute} has invalid image dimensions.",
            "distinct": "The {attribute} field has a duplicate value.",
            "email": "The {attribute} must be a valid email address.",
            "ends_with": "The {attribute} must end with one of the following: {values}.",
            "exists": "The selected {attribute} is invalid.",
            "file": "The {attribute} must be a file.",
            "filled": "The {attribute} field must have a value.",
            "gt": {
                "numeric": "The {attribute} must be greater than {value}.",
                "file": "The {attribute} must be greater than {value} kilobytes.",
                "string": "The {attribute} must be greater than {value} characters.",
                "array": "The {attribute} must have more than {value} items."
            },
            "gte": {
                "numeric": "The {attribute} must be greater than or equal {value}.",
                "file": "The {attribute} must be greater than or equal {value} kilobytes.",
                "string": "The {attribute} must be greater than or equal {value} characters.",
                "array": "The {attribute} must have {value} items or more."
            },
            "image": "The {attribute} must be an image.",
            "in": "The selected {attribute} is invalid.",
            "in_array": "The {attribute} field does not exist in {other}.",
            "integer": "The {attribute} must be an integer.",
            "ip": "The {attribute} must be a valid IP address.",
            "ipv4": "The {attribute} must be a valid IPv4 address.",
            "ipv6": "The {attribute} must be a valid IPv6 address.",
            "json": "The {attribute} must be a valid JSON string.",
            "lt": {
                "numeric": "The {attribute} must be less than {value}.",
                "file": "The {attribute} must be less than {value} kilobytes.",
                "string": "The {attribute} must be less than {value} characters.",
                "array": "The {attribute} must have less than {value} items."
            },
            "lte": {
                "numeric": "The {attribute} must be less than or equal {value}.",
                "file": "The {attribute} must be less than or equal {value} kilobytes.",
                "string": "The {attribute} must be less than or equal {value} characters.",
                "array": "The {attribute} must not have more than {value} items."
            },
            "max": {
                "numeric": "The {attribute} must not be greater than {max}.",
                "file": "The {attribute} must not be greater than {max} kilobytes.",
                "string": "The {attribute} must not be greater than {max} characters.",
                "array": "The {attribute} must not have more than {max} items."
            },
            "mimes": "The {attribute} must be a file of type: {values}.",
            "mimetypes": "The {attribute} must be a file of type: {values}.",
            "min": {
                "numeric": "The {attribute} must be at least {min}.",
                "file": "The {attribute} must be at least {min} kilobytes.",
                "string": "The {attribute} must be at least {min} characters.",
                "array": "The {attribute} must have at least {min} items."
            },
            "not_in": "The selected {attribute} is invalid.",
            "not_regex": "The {attribute} format is invalid.",
            "numeric": "The {attribute} must be a number.",
            "password": "The password is incorrect.",
            "present": "The {attribute} field must be present.",
            "regex": "The {attribute} format is invalid.",
            "required": "The {attribute} field is required.",
            "required_if": "The {attribute} field is required when {other} is {value}.",
            "required_unless": "The {attribute} field is required unless {other} is in {values}.",
            "required_with": "The {attribute} field is required when {values} is present.",
            "required_with_all": "The {attribute} field is required when {values} are present.",
            "required_without": "The {attribute} field is required when {values} is not present.",
            "required_without_all": "The {attribute} field is required when none of {values} are present.",
            "same": "The {attribute} and {other} must match.",
            "size": {
                "numeric": "The {attribute} must be {size}.",
                "file": "The {attribute} must be {size} kilobytes.",
                "string": "The {attribute} must be {size} characters.",
                "array": "The {attribute} must contain {size} items."
            },
            "starts_with": "The {attribute} must start with one of the following: {values}.",
            "string": "The {attribute} must be a string.",
            "timezone": "The {attribute} must be a valid timezone.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "url": "The {attribute} must be a valid URL.",
            "uuid": "The {attribute} must be a valid UUID.",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "current_password": "The password is incorrect.",
            "multiple_of": "The {attribute} must be a multiple of {value}.",
            "prohibited": "The {attribute} field is prohibited.",
            "prohibited_if": "The {attribute} field is prohibited when {other} is {value}.",
            "prohibited_unless": "The {attribute} field is prohibited unless {other} is in {values}.",
            "attributes": []
        }
    }
}
