export default {


    AppSetting(state) {

        return state.settings;
    },
    settingKey(state, key = null) {
        let sett = state.settings;
        if (key != null && sett[key]) {
            return sett[key];
        }

        return "";
    },
    phoneObject: state => state.phoneObject,
    setting_auth: state => state.setting_auth,
    recaptcha: state => state.recaptcha,
    recaptchaVerifier: state => state.recaptchaVerifier,
};
