import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'



import admin from './admin/index.js'
// import apps from './routes/apps'
// import dashboard from './routes/dashboard'
// import uiElements from './routes/ui-elements/index'

// import chartsMaps from './routes/charts-maps'
// import formsTable from './routes/forms-tables'
// import others from './routes/others'
// import pages from './routes/pages'

import authPages from './routes/authPages.js'

import front from './front/index.js';


Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    //   { 
    //      path: '/',
    //      redirect: {
    //       name: 'dashboard-ecommerce' 
    //     } 
    // },
    ...front,
    ...admin,
    // ...pages,
    // ...apps,
    // ...dashboard,
    // ...chartsMaps,
    // ...formsTable,
    // ...uiElements,
    // ...others,
    ...authPages,
    {
      path: '*',
      redirect: '404',
    },
  ],
})
// console.log(router)
const isLoggedIn = isUserLoggedIn()

// console.log("isLoggedIn",isLoggedIn)
router.beforeEach((to, _, next) => {

  if (to.matched.some(record => record.meta.requiresAuth == true)) {
    if (!canNavigate(to)) {

      //  console.log('to',to)
      // Redirect to login if not logged in
      if (!isLoggedIn) return next({ name: 'auth-login' })

      // If logged in => not authorized
      return next({ name: '403' })
    }

    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
      const userData = getUserData()
      next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    }

  }
  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
