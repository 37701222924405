import { Ability } from '@casl/ability'
import { initialAbility } from './config'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this


let stringifiedUserAbilities = localStorage.getItem('userAbilities');
//console.log("stringifiedUserAbilities",stringifiedUserAbilities)
   let existingAbility =null;
 if (localStorage.getItem('userAbilities')!=="undefined" && localStorage.getItem('userAbilities')!==undefined) {

    //console.log("userAbilities",localStorage.getItem('userAbilities'))
     existingAbility = JSON.parse(stringifiedUserAbilities);
 }

export default new Ability(existingAbility || initialAbility)
