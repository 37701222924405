import Vue from 'vue';
import $ from 'jquery';
import { formatMoney } from 'accounting-js/lib/index.js';
let $resetData = function (objectRow) {

  Object.entries(objectRow).forEach(([key, val]) => {
    if (typeof objectRow[key] === 'number') {
      objectRow[key] = 0;
    } else {
      objectRow[key] = "";
    }

  });

  return objectRow;

}

Vue.prototype.$resetData = $resetData;


$('[type="number"],input[type="number"]').keypress(function (evt) {
  // console.log(evt.keyCode);
  var charCode = (evt.which) ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    if (charCode != 46) {
      return false;
    }

  }
  return true;
});


let __p_currency_symbol = "R.S";
//let __p_currency_symbol = "SAR";
let __currency_symbol = "ر.س";
let __p_currency_thousand_separator = ",";
let __currency_thousand_separator = ",";
let __p_currency_decimal_separator = ".";
let __currency_decimal_separator = ".";
let __quantity_precision = 2;
let __currency_symbol_placement = 'after';
let symbol;
Vue.prototype.__formatMoney = function (
  number,
  show_symbol = true,
  use_page_currency = false,
  precision = 2,
  is_quantity = false
) {
  if (use_page_currency && __p_currency_symbol) {
    var s = __p_currency_symbol;
    var thousand = __p_currency_thousand_separator;
    var decimal = __p_currency_decimal_separator;
  } else {
    var s = __currency_symbol;
    var thousand = __currency_thousand_separator;
    var decimal = __currency_decimal_separator;
  }

  symbol = '';
  var format = '%s%v';
  if (show_symbol) {
    symbol = s;
    format = '%s %v';
    if (__currency_symbol_placement == 'after') {
      format = '%v %s';
    }
  }

  if (is_quantity) {
    precision = __quantity_precision;
  }
  //  * accounting.formatMoney(4999.99, { symbol: "€", precision: 2, thousand: ".", decimal: "," }); // €4.999,99

  return formatMoney(number, { symbol, precision, thousand, decimal, format });
}    