import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import catalogeStoreModule from '@/views/front/cataloge/catalogeStoreModule'
import productStoreModule from '@/views/front/products/productStoreModule'
import orderStoreModule from '@/views/front/orders/orderStoreModule'

import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import settings from "./settings/index";
import auth from "./modules/auth";
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    'app-ecommerce': ecommerceStoreModule,
    'cataloge': catalogeStoreModule,
    'myproducts': productStoreModule,
    'orders': orderStoreModule,
    setting: settings,
  },
  strict: process.env.DEV,
})
