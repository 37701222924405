
import axios from '@axios'
import router from '@/router'
import store from '@/store'
import { getUserData, getAbilities, LogoutUser } from '@/auth/utils.js'
import i18n from '@/libs/i18n'
// import { useAppAbility } from '@/plugins/casl/useAppAbility'
import useJwt from '@/auth/jwt/useJwt'
import { Ability } from '@casl/ability'

const ability = new Ability([])

const userData = getUserData()
const abilities = getAbilities()
// console.log('getAbilities',abilities)
const state = {
    isAuthenticated: false,
    userRole: '',
    Permissions: null,
    user: userData || {},
    userAbilities: abilities ?? [],
    navMenuItems: [],
    dataAuth: {},
    loading: false,
    error: null,
    contactType: null,
    notifs: 0,
    balance: 0.00,


}

const getters = {
    isAuthenticated: state => state.isAuthenticated,
    currentUser: state => state.user,
    currentUserPermissions: state => state.Permissions,
    currentUserRole: state => state.userRole,
    currentUserAbilities: state => state.userAbilities,
    navMenuItems: state => state.navMenuItems,
    getDataAuth: state => state.dataAuth,
    loading: state => state.loading,
    notifs_alert: state => state.notifs,
    error: state => state.error,


}

const mutations = {
    setLoading(state, data) {
        state.loading = data
        state.error = null
    },
    setError(state, data) {
        state.error = data
        state.loggedInUser = null
        state.loading = false
    },
    clearError(state) {
        state.error = null
    },
    setAbilities(state, Abilities) {
        state.userAbilities = Abilities
    },
    setnavMenuItems(state, navMenuItems) {
        state.navMenuItems = navMenuItems
    },
    setPermissions(state, Permissions) {
        state.Permissions = Permissions
    },
    setDataAuth(state, dataAuth) {
        state.dataAuth = dataAuth
    },

    setUser(state, user) {
        state.user = user
        state.balance = user.balance;
        state.contactType = user.contact_type;
        state.isAuthenticated = true
    },
    setUserRole(state, userRole) {
        state.userRole = userRole
        localStorage.removeItem('userRole')
        localStorage.setItem('userRole', userRole)
    },

    SetDefaultLanguage(state, Language) {
        if (i18n.locale == undefined) {
            i18n.locale = Language
            store.dispatch('language/setLanguage', Language)
            localStorage.setItem('language', Language)

        }
    },


    Notifs_alert(state, notifs) {
        state.notifs = notifs
    },

    logout(state) {
        state.user = null
        state.Permissions = null
        state.loggedInUser = null
        state.loading = false
        state.error = null
        localStorage.removeItem('accessToken')
        localStorage.removeItem('userData')
        localStorage.removeItem('userAbilities')
    },
}

const actions = {


    setDataAuth({ commit }, data) {
        commit('setDataAuth', data)
    },
    async refreshSetting(context) {
        const self = this
        await axios.get('getSettings').then(userAuth => {
            const Permissions = userAuth.data.permissions
            const { user } = userAuth.data
            const { notifs } = userAuth.data
            //  const { default_language } = userAuth.data.user

            context.commit('setPermissions', Permissions)
            context.commit('setUser', user)
            context.commit('Notifs_alert', notifs)

            // context.commit('SetDefaultLanguage', default_language)
        }).catch(() => {
            context.commit('setPermissions', null)
            context.commit('setUser', null)
            context.commit('Notifs_alert', null)
            // context.commit('SetDefaultLanguage', 'ar')
        })
    },
    async refreshUser(context) {
        const self = this
        await axios.get('fetchDataUser').then(userAuth => {
            //  let Permissions = userAuth.data.permissions
            const user = userAuth.data

            context.commit('setUser', user)

        }).catch(() => {

        })
    },
    async updateUserData(context) {
        const self = this
        await axios.get('check-login').then(userAuth => {
            const accessToken = response.data.access_token
            const userData = response.data.result
            const { userAbilities } = response.data
            const { userRole } = response.data
            const { redirect_name } = response.data
            const abilitylist = userAuth.data.abilities

            localStorage.setItem('userAbilities', JSON.stringify(userAbilities))

            localStorage.setItem('userData', JSON.stringify(userData))
            localStorage.setItem('accessToken', accessToken)
            localStorage.setItem('userRole', userRole)
            useJwt.setToken(accessToken)
            useJwt.setRefreshToken(accessToken)
            ability.update(userAbilities)
            window.location = `/app/${redirect_name}`
            // self.$ability.update(abilitylist)
        }).catch(() => {
            localStorage.removeItem('userRole')
            localStorage.removeItem('accessToken')
            localStorage.removeItem('userData')
            localStorage.removeItem('userAbilities')
            window.location.href = '/login'
            // context.commit('setPermissions', null)
            // context.commit('setUser', null)
            //  context.commit('Notifs_alert', null)
            //  context.commit('SetDefaultLanguage', 'ar')
        })
    },
    logout({ commit }) {
        LogoutUser()
        axios.get('/logout')

        router.push({ name: 'auth-login' })
        // axios.get('/logout')
        //   .then((userData) => {

        //     window.location.href='/login';
        // })
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
