export default {

  Loadsettings({ commit, state }) {
    axios.get('/Loadsettings').then(response => {
      //commit('clear');
      commit('updateSetting', response.data);

    });
  },
  setPhoneObject({ commit }, data) {
    commit('setPhoneData', data)
  },
  setSettingAuth({ commit }, data) {
    commit('setSettingAuth', data)
  },
  setRecaptchaVerifierToken({ commit }, data) {
    commit('setRecaptchaVerifierData', data)
  },
  setRecaptchaVerifier({ commit }, data) {
    commit('setRecaptchaVerifierObject', data)
  },


};
