import useJwt from '@/auth/jwt/useJwt'

export const isUserLoggedIn = () => {
  return localStorage.getItem('userData') && getUserData().id && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

// export const getUserData = () => {
//  // console.log(localStorage.getItem('accessToken'))
//  // console.log(localStorage.getItem('userData'))
//     if(localStorage.getItem('userData')!==undefined && localStorage.getItem('userData')!=="undefined"){
//       return JSON.parse(localStorage.getItem('userData'));
//     }
//   return undefined;
// }
export const getUserRole = () => {

  if (getUserData() && getUserData().role_name == localStorage.getItem('userRole')) {
    return getUserData().role_name;
  }
  return localStorage.getItem('userRole');
}

export const getAbilities = () => {
  // if (getUserData() && getUserData().abilities) {
  // //  return  getUserData.abilities
  // }
  if (localStorage.getItem('userAbilities') !== undefined && localStorage.getItem('userAbilities') !== "undefined") {
    return JSON.parse(localStorage.getItem('userAbilities'));
  }
  return undefined;
}
export const getAccessToken1 = () => localStorage.getItem('accessToken')
export const getAccessToken = () => localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
// export const isUserLoggedIn = () => {
//    return (getUserData() && (getAccessToken()!=="undefined" && getAccessToken()!==undefined));
//   return false;
// }
export const LogoutUser = () => {
  localStorage.removeItem('userRole')
  localStorage.removeItem('accessToken')
  localStorage.removeItem('userData')
  localStorage.removeItem('userAbilities')

  localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
  localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
}
/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */

export const getIsAdmin = function () {
  let userRole = getUserRole();
  if (userRole === 'admin' || userRole === 'subadmin' || userRole === 'developer') {
    return true;
  }

  return false;
}
/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
// export const isUserLoggedIn = () => {
//   return localStorage.getItem('userData') && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
// }



/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {

  return '/admin';
}
