

export default [

      {
            path: '/admin',
            name: 'app-admin',
            redirect: "/admin/dashboard",
            meta: { requiresAuth: true },
            component: () => import("@/views/route-view.vue"),
            /*{ name: 'apps-dashboard' }*/
            children: [

                  {
                        path: "dashboard",
                        name: "dashboard.index",
                        component: () => import("@/views/front/cataloge/shop/index.vue"),
                        // component: () =>
                        //       import("@/views/admin/dashboard/dashboard"),
                        meta: { resource: 'dashboard', action: 'index', requiresAuth: true },
                  },

            ]
      },



];




//

// 